import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const theme = {
  colors: {
    primary: {
      50: '#017e6d',
      100: '#017e6d',
      200: '#017e6d',
      300: '#017e6d',
      400: '#017e6d',
      500: '#017e6d',
      600: '#017e6d',
      700: '#017e6d',
      800: '#017e6d',
      900: '#017e6d',
    },
  },
}

const breakpoints = createBreakpoints({
  sm: '480px',
  md: '720px',
  lg: '1024px',
  xl: '1280px',
  xx: '1440px',
  mw: '1760px',
})

export default extendTheme({ ...theme, breakpoints })
